*, *:before, *:after {
  box-sizing: border-box;
}
/* #Navigation
================================================== */
.start-header {
  opacity: 1;
  transform: translateY(0);
  padding: 20px 0;
  box-shadow: 0 10px 30px 0 rgba(138, 155, 165, 0.15);
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

  .start-header.scroll-on {
    box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
    padding: 10px 0;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }

    .start-header.scroll-on .navbar-brand img {
      height: 24px;
      -webkit-transition: all 0.3s ease-out;
      transition: all 0.3s ease-out;
    }

.container-fullw {
  padding-right: 45px !important;
  padding-left: 45px !important;
}

.navigation-wrap {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.nav-hr {
  border-bottom: 10px solid #87be40;
}

.navbar {
  padding: 0;
}

.navbar-brand img {
  height: 35px;
  width: auto;
  display: block;
  /*filter: brightness(10%);*/
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.navbar-toggler {
  float: right;
  border: none;
  padding-right: 0;
}

  .navbar-toggler:active,
  .navbar-toggler:focus {
    outline: none;
  }

.navbar-light .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 1px solid #000;
  transition: all 300ms linear;
}

  .navbar-light .navbar-toggler-icon:after,
  .navbar-light .navbar-toggler-icon:before {
    width: 24px;
    position: absolute;
    height: 1px;
    background-color: #000;
    top: 0;
    left: 0;
    content: '';
    z-index: 2;
    transition: all 300ms linear;
  }

  .navbar-light .navbar-toggler-icon:after {
    top: 8px;
  }

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}

.nav-link {
  color: #212121 !important;
  font-weight: 500;
  transition: all 200ms linear;
}

.nav-link-white {
  color: #fff !important;
  font-weight: 500;
  transition: all 200ms linear;
}

.nav-link-blue {
  color: #005eb8 !important;
  font-weight: 500;
  transition: all .2s linear;
  text-decoration: none;
  line-height: 2.1;
}

/*.nav-item:hover .nav-link {
  color: #fff !important;
}*/
.nav-item {
  padding: 0 20px;
  display: inline-block;
  padding: 0 20px;
  border-width: 0;
  position: relative;
  border-color: transparent;
  background-color: transparent;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-backface-visibility: hidden;
  line-height: 1.5;
  padding-left: 8px !important;
  padding-right: 8px !important;
}

  .nav-item.active .nav-link {
    color: #fff !important;
  }

.nav-link {
  position: relative;
  padding: 5px 0 !important;
  display: inline-block;
}

.nav-item:hover:after {
  bottom: 0 !important;
  opacity: 1 !important;
}

.nav-item.active:hover:after {
  opacity: 0;
}

.logout-btn {
  letter-spacing: 1px;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem !important;
  color: #fff !important;
  background-color: #88be42;
  border-style: solid;
  border-width: 1px;
  border-color: var(--e-global-color-322b4d9);
  box-shadow: 0 0 5px 0 rgb(0 0 0 / 50%);
  font-size: 16px;
  width: max-content;
}

  .logout-btn:hover {
    background: #000;
  }

/*.navbar-nav li:hover a {
  color: #88be42;
}*/

.nav-item {
  position: relative;
  transition: all 200ms linear;
}

/* #Primary style
================================================== */

.bg-light {
  background-color: #fff !important;
  transition: all 200ms linear;
}

.bg-light-blue {
  background: #87be40;
}

.bg-blue {
  background-color: #2175c8 !important;
}

.bg-grey {
  background-color: #9ba2a947 !important;
}

.bg-success {
  background-color: #57e25c !important;
}

.section {
  position: relative;
  width: 100%;
  display: block;
}

.full-height {
  height: 100vh;
}

.over-hide {
  overflow: hidden;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  margin-top: 40px;
  transform: translateY(-50%);
  z-index: 20;
}

a:hover {
  text-decoration: none;
  color: #000;
}

.text-blue {
  color: #0056b3 !important;
}

.text-red {
  color: red !important;
}

.text-orange {
  color: orange !important;
}

.text-green {
  color: green !important;
}

h4.card-title {
  margin: 0px;
  padding: 0px;
}

h1 {
  font-size: 48px;
  line-height: 1.2;
  font-weight: 700;
  color: #212112;
  text-align: center;
}

.title-blue {
  font-size: 20px;
  margin-bottom: 50px;
  color: #88be42 !important;
  font-weight: 500;
}

.font-larger {
  font-size: large !important;
}

.font-primary {
  color: #88be42;
}

.fs-20px {
  font-size: 20px;
}

.fw-800 {
  font-weight: 800;
}

p {
  text-align: left;
  margin: 0;
  opacity: 1;
  /*padding-top: 10px;
  transform: translate(0);
  transition: all 300ms linear;
  transition-delay: 1700ms;*/
}

body.hero-anime p {
  opacity: 0;
  transform: translateY(40px);
  transition-delay: 1700ms;
}

h1 span {
  display: inline-block;
  transition: all 300ms linear;
  opacity: 1;
  transform: translate(0);
}

body.hero-anime h1 span:nth-child(1) {
  opacity: 0;
  transform: translateY(-20px);
}

body.hero-anime h1 span:nth-child(2) {
  opacity: 0;
  transform: translateY(-30px);
}

body.hero-anime h1 span:nth-child(3) {
  opacity: 0;
  transform: translateY(-50px);
}

body.hero-anime h1 span:nth-child(4) {
  opacity: 0;
  transform: translateY(-10px);
}

body.hero-anime h1 span:nth-child(5) {
  opacity: 0;
  transform: translateY(-50px);
}

body.hero-anime h1 span:nth-child(6) {
  opacity: 0;
  transform: translateY(-20px);
}

body.hero-anime h1 span:nth-child(7) {
  opacity: 0;
  transform: translateY(-40px);
}

body.hero-anime h1 span:nth-child(8) {
  opacity: 0;
  transform: translateY(-10px);
}

body.hero-anime h1 span:nth-child(9) {
  opacity: 0;
  transform: translateY(-30px);
}

body.hero-anime h1 span:nth-child(10) {
  opacity: 0;
  transform: translateY(-20px);
}

h1 span:nth-child(1) {
  transition-delay: 1000ms;
}

h1 span:nth-child(2) {
  transition-delay: 700ms;
}

h1 span:nth-child(3) {
  transition-delay: 900ms;
}

h1 span:nth-child(4) {
  transition-delay: 800ms;
}

h1 span:nth-child(5) {
  transition-delay: 1000ms;
}

h1 span:nth-child(6) {
  transition-delay: 700ms;
}

h1 span:nth-child(7) {
  transition-delay: 900ms;
}

h1 span:nth-child(8) {
  transition-delay: 800ms;
}

h1 span:nth-child(9) {
  transition-delay: 600ms;
}

h1 span:nth-child(10) {
  transition-delay: 700ms;
}

body.hero-anime h1 span:nth-child(11) {
  opacity: 0;
  transform: translateY(30px);
}

body.hero-anime h1 span:nth-child(12) {
  opacity: 0;
  transform: translateY(50px);
}

body.hero-anime h1 span:nth-child(13) {
  opacity: 0;
  transform: translateY(20px);
}

body.hero-anime h1 span:nth-child(14) {
  opacity: 0;
  transform: translateY(30px);
}

body.hero-anime h1 span:nth-child(15) {
  opacity: 0;
  transform: translateY(50px);
}

h1 span:nth-child(11) {
  transition-delay: 1300ms;
}

h1 span:nth-child(12) {
  transition-delay: 1500ms;
}

h1 span:nth-child(13) {
  transition-delay: 1400ms;
}

h1 span:nth-child(14) {
  transition-delay: 1200ms;
}

h1 span:nth-child(15) {
  transition-delay: 1450ms;
}

#switch,
#circle {
  cursor: pointer;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

#switch {
  width: 60px;
  height: 8px;
  border: 2px solid #000f9f;
  border-radius: 27px;
  background: #000;
  position: relative;
  display: block;
  margin: 0 auto;
  text-align: center;
  opacity: 1;
  transform: translate(0);
  transition: all 300ms linear;
  transition-delay: 1900ms;
}

body.hero-anime #switch {
  opacity: 0;
  transform: translateY(40px);
  transition-delay: 1900ms;
}

#circle {
  position: absolute;
  top: -11px;
  left: -13px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: #000;
}

.switched {
  border-color: #000 !important;
  background: #000f9f !important;
}

  .switched #circle {
    left: 43px;
    box-shadow: 0 4px 4px rgba(26,53,71,0.25), 0 0 0 1px rgba(26,53,71,0.07);
    background: #fff;
  }

.nav-item .dropdown-menu {
  transform: translate3d(0, 10px, 0);
  visibility: hidden;
  opacity: 0;
  max-height: 0;
  display: block;
  padding: 0;
  margin: 0;
  transition: all 200ms linear;
}

.nav-item.show .dropdown-menu {
  opacity: 1;
  visibility: visible;
  max-height: 999px;
  transform: translate3d(0, 0px, 0);
}

.dropdown-menu {
  padding: 10px !important;
  margin-top: 10px;
  font-size: 13px;
  letter-spacing: 1px;
  color: #212121;
  background-color: #ffffff;
  border: none;
  transition: all 200ms linear;
  border: 1px solid #88be42 !important;
  border-radius: unset !important;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-item {
  padding: 3px 15px;
  color: #212121;
  border-radius: 2px;
  transition: all 200ms linear;
  font-size: 14px;
  word-break: break-word;
  white-space: initial;
  display: flex;
  align-items: baseline;
  flex-direction: row;
}

li.dropdown-item input[type='checkbox'] {
  margin-right: 10px;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #000;
  background-color: #dcdcdc;
}

body.dark {
  color: #fff;
  background-color: #1f2029;
}

  body.dark .navbar-brand img {
    filter: brightness(100%);
  }

  body.dark h1 {
    color: #fff;
  }

    body.dark h1 span {
      transition-delay: 0ms !important;
    }

  body.dark p {
    color: #fff;
    transition-delay: 0ms !important;
  }

  body.dark .bg-light {
    background-color: #14151a !important;
  }

  body.dark .start-header {
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
  }

    body.dark .start-header.scroll-on {
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
    }

  body.dark .nav-link {
    color: #fff !important;
  }

  body.dark .nav-item.active .nav-link {
    color: #999 !important;
  }

  body.dark .dropdown-menu {
    color: #fff;
    background-color: #1f2029;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
  }

  body.dark .dropdown-item {
    color: #fff;
  }

  body.dark .navbar-light .navbar-toggler-icon {
    border-bottom: 1px solid #fff;
  }

    body.dark .navbar-light .navbar-toggler-icon:after,
    body.dark .navbar-light .navbar-toggler-icon:before {
      background-color: #fff;
    }

  body.dark .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
    border-color: transparent;
  }

.navbar-light .navbar-nav .nav-link {
  color: #000;
}

/* #Media
================================================== */

@media (max-width: 767px) {
  h1 {
    font-size: 38px;
  }

  .nav-item:after {
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 2px;
    content: '';
    background-color: #88be42;
    opacity: 0;
    transition: all .2s linear;
  }

  .nav-item::before {
    position: absolute;
    display: block;
    top: 15px;
    left: 0;
    width: 11px;
    height: 1px;
    content: "";
    border: none;
    background-color: #000;
    vertical-align: 0;
  }

  .dropdown-toggle::after {
    position: absolute;
    display: block;
    top: 10px;
    left: -23px;
    width: 1px;
    height: 11px;
    content: "";
    border: none;
    background-color: #000;
    vertical-align: 0;
    transition: all 200ms linear;
  }

  .dropdown-toggle[aria-expanded="true"]::after {
    transform: rotate(90deg);
    opacity: 0;
  }

  .dropdown-menu {
    padding: 0 !important;
    background-color: transparent;
    box-shadow: none;
    transition: all 200ms linear;
  }

  .dropdown-toggle[aria-expanded="true"] + .dropdown-menu {
    margin-top: 10px !important;
    margin-bottom: 20px !important;
  }

  body.dark .nav-item::before {
    background-color: #fff;
  }

  body.dark .dropdown-toggle::after {
    background-color: #fff;
  }

  body.dark .dropdown-menu {
    background-color: transparent;
    box-shadow: none;
  }
}

/* #Link to page
================================================== */

.logo {
  position: absolute;
  bottom: 30px;
  right: 30px;
  display: block;
  z-index: 100;
  transition: all 250ms linear;
}

  .logo img {
    height: 35px;
    width: auto;
    display: block;
    /*filter: brightness(10%);*/
    transition: all 250ms linear;
  }

body.dark .logo img {
  filter: brightness(100%);
}

a.primary-content {
  color: #0056b3;
}

a {
  color: #000;
  cursor: pointer;
}

code {
  color: #e01a76;
}

body {
  background: #F5F5F5;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.no-data-available {
  text-align: center;
}

.ngHide {
  display: none;
}

.font-larger {
  font-size: large !important;
}

/*.font-x-larger {
  font-size: x-large !important;
}*/


/*.sub-nav li {
  width: 16rem;
  background: #f1eded;
  border: 1px solid #e0cfcf;
}*/

/*.sub-nav .nav-item {
  background: #f1eded !important;
  border: 1px solid #e0cfcf !important;
}*/

.sub-nav .nav-item a {
  color: #88be42 !important
}




.mt-7 {
  margin-top: 8rem !important;
}

.hr-red {
  border-top: 1.7px solid #d9534f94;
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

.col-black {
  color: black !important;
}

.animationload {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.85);
  z-index: 999999;
}

  .animationload .loader {
    width: 200px;
    height: 200px;
    font-size: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    background-image: url('assets/images/loader.gif');
    background-repeat: no-repeat;
    background-position: center;
    margin: -100px 0 0 -100px;
  }

.disabledinput {
  background: #dcdcdc !important;
}

.mt-2rem {
  margin-top: 2rem;
}

.rounded-circle {
  border-radius: 50%;
}

.bootstrap-datetimepicker-widget.dropdown-menu {
  z-index: 10000 !important;
}

footer {
  position: relative;
  bottom: 0;
  width: 97%;
  left: 15px;
  padding-right: 0;
  margin-left: -15px;
  padding-top: 44px;
  clear: both;
}

.sidebar ol li a:hover {
  color: #005eb8;
  cursor: pointer;
}

.right-sidebar {
  background: #FFF;
  width: 20%;
  padding-top: 0%;
  height: 100%;
  right: 0;
  float: right;
  position: static;
}

span.icon {
  vertical-align: bottom;
  padding-right: 16px;
}

.menu-main ul li.active a {
  background: #48b0d0;
}

.menu-main ul li:hover a {
  background: #48b0d0;
}

span.mail-number {
  position: absolute;
  right: 30px;
  top: 12px;
  width: 53px;
  height: 21px;
  background: #FFF;
  color: red;
  border-radius: 20px;
  text-align: center;
}

.main-content
h4 {
  text-align: left;
  font-family: Interstate;
  text-shadow: none;
  color: #323544;
  font-size: 30px;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 20px !important;
  margin-top: 20px !important;
}

.basic-page .form-control {
  display: block;
  width: 100%;
  padding: 10px ​12px;
  font-size: 16px !important;
  font-family: Interstate;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 2px solid #d3d3d3;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  /*box-shadow: 1px 1px 1px #d8d8d8;*/
  outline: 0;
  border-radius: 0px;
  height: 50px;
}

.form-control.bg-light {
  background: #f5f5f5 !important;
}

.form-horizontal .control-label {
  text-align: left !important;
  padding-top: 0px;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #f5f5f5;
  /*background-clip: padding-box;*/
  border: 2px solid #dedcdcbf;
  border-radius: unset;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.basic-page textarea.form-control {
  height: auto;
  resize: vertical;
}

.basic-page label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 18px;
  text-transform: inherit;
  color: #747474;
  color: #000000;
  font-family: Interstate;
}

.basic-page .form-group {
  margin-bottom: 20px;
}

.basic-page .btn-primary {
  outline: 0;
  font-style: normal;
  font-weight: 500;
  font-family: inherit;
  padding: 0 35px;
  font-size: 13px;
  line-height: 20px;
  height: 25px;
  text-transform: capitalize;
  letter-spacing: 1px;
  border-radius: 25px;
  border: 2px solid #e6e6e6;
  color: #fff;
  background-color: transparent;
  background: repeating-linear-gradient( 135deg, #2faee7 50%, #0770c4 100%);
  transition: color .2s ease-out,background-color .2s ease-out,border-color .2s ease-out;
  box-sizing: initial;
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
  min-width: 120px;
  padding: 11px 15px;
  margin-right: 15px;
  text-transform: uppercase;
  transition: all 400ms;
}

.btn-approval {
  outline: 0;
  font-style: normal;
  font-weight: 500;
  font-family: inherit;
  padding: 0 35px;
  font-size: 13px;
  line-height: 48px;
  height: 48px;
  text-transform: capitalize;
  letter-spacing: 1px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 25px;
  border: 2px solid #e6e6e6;
  color: #000;
  background-color: transparent;
  -webkit-transition: color .2s ease-out,background-color .2s ease-out,border-color .2s ease-out;
  transition: color .2s ease-out,background-color .2s ease-out,border-color .2s ease-out;
  -webkit-box-sizing: initial;
  -moz-box-sizing: initial;
  box-sizing: initial;
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
  min-width: 150px;
  padding: 11px 15px;
  margin-right: 15px;
  text-transform: uppercase;
  transition: all 400ms;
}

.btn.btn-primary.cancel {
  background: #b2c2c8;
  border-color: #b2c2c8;
}

.basic-page .btn-primary:hover, .btn-approval:hover {
  background-color: #205482;
  border-color: #205482;
  background-color: #1e73be;
  border-color: #1e73be;
  color: #fff;
}

.btn-approval.btn-success, select.form-control.select-success {
  background-color: #398439;
  border-color: #398439;
  color: #fff;
}

select.form-control.select-failure {
  background-color: red;
  border-color: red;
  color: #fff;
}

.sidebar ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

  .sidebar ol li {
    counter-increment: item;
    margin-bottom: 25px;
    position: relative;
    z-index: 1;
  }

    .sidebar ol li a {
      font-family: Interstate;
      font-weight: 500;
      text-transform: capitalize;
      color: #005eb8;
      text-decoration: none;
      font-size: 15px;
    }

      .sidebar ol li a span {
        position: absolute;
      }

    .sidebar ol li:before {
      margin-right: 10px;
      content: counter(item);
      background: #005eb8;
      border-radius: 100%;
      color: white;
      width: 35px;
      text-align: center;
      display: inline-block;
      height: 35px;
      line-height: 35px;
      font-size: 15px;
    }


.ft-1 p {
  font-size: 12px;
  color: #b2c2c8;
}

.ft-2 p {
  font-size: 12px;
  color: #b2c2c8;
  text-align: right;
}



.basic-page .btn-primary i {
  padding-left: 10px;
  padding-right: 10px;
}

.sidebar ol li.active-ol.done-act:before {
  background: #44c7b8;
  content: "\f00c";
  font-family: FontAwesome;
  font-size: 15px;
  color: #FFF;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  width: 100%;
}

.calr .input-group {
  position: relative;
  display: block;
}

.calr span.input-group-addon {
  background: none;
  border-radius: 0;
  border: none;
  box-shadow: none;
  display: inline-block;
  position: absolute;
  right: 16px;
  top: 6px;
  z-index: 9;
  color: #dcdcdc;
  font-size: 19px;
}

.basic-page .form-group {
  float: left;
  width: 100%;
}

.success-in svg {
  width: 80px;
  fill: #48b0d0;
}

.success-in {
  text-align: center;
}

  .success-in h1 {
    font-weight: 500;
    font-size: 22px;
    color: #48b0d0;
    margin-bottom: 30px;
  }

.btn.btn-group-sm {
  background: #44c7b8;
  color: #FFF;
  box-shadow: none;
  border: none;
  padding: 10px 20px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 1px;
}

.success-in {
  text-align: center;
  padding-top: 22%;
}

.right-sidebar.sidebar li.active-ol:last-child:after {
  content: normal;
}

.basic-page .btn-primary a {
  text-decoration: none;
  color: #FFF;
}

textarea#comment {
  min-height: 170px;
}


.checkbox label:after,
.radio label:after {
  content: '';
  display: table;
  clear: both;
}

.checkbox .cr, .radio .cr {
  position: relative;
  display: inline-block;
  border: 0px solid #a9a9a9;
  border-radius: .20em;
  width: 1.5em;
  height: 1.5em;
  float: left;
  margin-right: .5em;
  background: #FFF;
  box-shadow: 1px 1px 1px #a0a0a0;
}

.checkbox label {
  padding-left: 0;
}

.radio .cr {
  border-radius: 50%;
}

  .checkbox .cr .cr-icon,
  .radio .cr .cr-icon {
    position: absolute;
    font-size: .8em;
    line-height: 0;
    top: 50%;
    left: 20%;
  }

  .radio .cr .cr-icon {
    margin-left: 0.04em;
  }

.checkbox label input[type="checkbox"],
.radio label input[type="radio"] {
  display: none;
}

  .checkbox label input[type="checkbox"] + .cr > .cr-icon,
  .radio label input[type="radio"] + .cr > .cr-icon {
    transform: scale(3) rotateZ(-20deg);
    opacity: 0;
    transition: all .3s ease-in;
  }

  .checkbox label input[type="checkbox"]:checked + .cr > .cr-icon,
  .radio label input[type="radio"]:checked + .cr > .cr-icon {
    transform: scale(1) rotateZ(0deg);
    opacity: 1;
  }

  .checkbox label input[type="checkbox"]:disabled + .cr,
  .radio label input[type="radio"]:disabled + .cr {
    opacity: .5;
  }

.glyphicon-ok:before {
  content: "\e013";
  color: #159bbc;
}

.checkbox, .radio {
  position: relative;
  display: block;
  margin-top: 17px;
  margin-bottom: 20px;
  clear: both;
}

.mail-attach ul li {
  display: inline-block;
  margin-right: 35px;
}

  .mail-attach ul li a {
    text-decoration: none;
    font-size: 15px;
    vertical-align: middle;
    color: #48b0d0;
  }

span.icon-pdf {
  margin-right: 20px;
}

.on-close {
  margin-left: 5px;
}

.mail-attach h5 {
  margin: 25px 0;
}

.upload {
  float: left;
  clear: both;
  background: #FFF;
  width: 100%;
  padding: 14px 110px 25px;
  margin-top: 25px;
  margin-bottom: 25px;
}


  .upload h5 {
    font-size: 15px;
    color: #888;
    margin-bottom: 15px;
  }

.progress-bar {
  float: left;
  width: 0;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: #337ab7;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0,0,0,.15);
  box-shadow: inset 0 -1px 0 rgba(0,0,0,.15);
  -webkit-transition: width .6s ease;
  -o-transition: width .6s ease;
  transition: width .6s ease;
}


.progress-bar {
  float: left;
  width: 0;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: #337ab7;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0,0,0,.15);
  box-shadow: inset 0 -1px 0 rgba(0,0,0,.15);
  -webkit-transition: width .6s ease;
  -o-transition: width .6s ease;
  transition: width .6s ease;
  background: rgba(21,155,188,1);
  background: -moz-linear-gradient(left, rgba(21,155,188,1) 0%, rgba(23,166,152,1) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(21,155,188,1)), color-stop(100%, rgba(23,166,152,1)));
  background: -webkit-linear-gradient(left, rgba(21,155,188,1) 0%, rgba(23,166,152,1) 100%);
  background: -o-linear-gradient(left, rgba(21,155,188,1) 0%, rgba(23,166,152,1) 100%);
  background: -ms-linear-gradient(left, rgba(21,155,188,1) 0%, rgba(23,166,152,1) 100%);
  background: linear-gradient(to right, rgba(21,155,188,1) 0%, rgba(23,166,152,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#159bbc', endColorstr='#17a698', GradientType=1 );
  border-radius: 19px;
}

.progress {
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
  box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
}

h5 span {
  color: #41aed0;
}


.txt-on textarea {
  min-height: 100px !important;
}



.progress p {
  margin: 17px 0 10px;
  clear: both;
  color: #868383;
  z-index: 99999;
  font-size: 15px;
  position: absolute;
  right: 120px;
  bottom: 25px;
  font-style: italic;
  font-size: 13px;
}

.full-width {
  width: 96%;
}

button.btn-edit {
  border: none;
  background: #FFF;
  box-shadow: 1px 1px 1px #DDD;
  border-radius: 3px;
  height: 32px;
  text-align: center;
  width: 34px;
  outline: none;
  padding: 5px 6px;
  margin-right: 8px;
}

.form-group.number-sec {
  width: auto;
  margin-bottom: 0;
  position: relative;
  display: inline-block;
  float: none;
  vertical-align: top;
}

option:hover {
  background-color: #88be42;
  cursor: default;
}

::selection {
  color: #fff;
  background-color: #88be42;
  background: #88be42;
}

.multiselect-item-checkbox input[type=checkbox] + div:before {
  box-sizing: content-box;
  content: "";
  color: #88be42 !important;
  position: absolute;
  top: 50%;
  left: 0;
  width: 14px;
  height: 14px;
  margin-top: -9px;
  border: 2px solid #88be42 !important;
  text-align: center;
  transition: .4s;
}

.multiselect-dropdown span.selected-item a {
  font-size: small;
  vertical-align: text-bottom;
  color: #87be40 !important;
}

.multiselect-item-checkbox input[type=checkbox]:checked + div:before {
  -webkit-animation: .2s ease-in borderscale;
  animation: .2s ease-in borderscale;
  background: #88be42 !important;
}
/*.multiselect-item-checkbox input[type=checkbox] + div:before {
  box-sizing: content-box;
  content: "";
  color: #88be42;
  position: absolute;
  top: 50%;
  left: 0;
  width: 14px;
  height: 14px;
  margin-top: -9px;
  border: 2px solid #88be42;
  text-align: center;
  transition: .4s;
}

.multiselect-item-checkbox input[type=checkbox]:checked + div:before {
  -webkit-animation: .2s ease-in borderscale;
  animation: .2s ease-in borderscale;
  background: #88be42;
}
*/
span.dropdown-btn span span {
  padding-right: 24px !important;
  vertical-align: middle;
}

select#sel1 {
  width: 44px;
  height: 32px;
  padding: 0px 6px;
  color: #c9c9c9;
  vertical-align: middle;
}


.form-group.number-sec label {
  position: absolute;
  top: 7px;
  width: 139px;
  display: block;
  max-width: 107px !important;
  font-weight: 500 !important;
  color: #a09a9a;
  text-transform: none !IMPORTANT;
  font-size: 13px;
  right: -97px;
}

.auto-height {
  height: auto !important;
}

.sec-one {
  float: left;
  width: 100%;
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  padding: 14px 0 13px;
}

.form-group.search {
  margin-bottom: 0;
  max-width: 195px;
  max-width: 25em;
  width: 100%;
  margin-left: 140px;
  font-size: 13px !important;
  float: none;
  display: inline-block;
  margin-right: 25px;
}

label.input-group.stylish-input-group {
  background: #eaf8fc;
  background-image: -moz-linear-gradient(#fff, #d4e8ec);
  background-image: -webkit-gradient(linear,left bottom,left top,color-stop(0, #d4e8ec),color-stop(1, #fff));
  -moz-border-radius: 35px;
  border-radius: 35px;
  border-width: 1px;
  border-style: solid;
  border-color: #c4d9df #a4c3ca #83afb7;
  width: 15em;
  height: 51px;
  padding: 10px;
  margin: 100px auto 50px;
  overflow: hidden;
}

  label.input-group.stylish-input-group .form-control {
    padding: 5px 9px;
    height: 30px;
    width: 380px;
    border: 1px solid #a4c3ca;
    font: normal 13px 'trebuchet MS', arial, helvetica;
    background: #f1f1f1;
    -moz-border-radius: 50px 3px 3px 50px;
    border-radius: 50px;
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25) inset, 0 1px 0 rgba(255, 255, 255, 1);
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25) inset, 0 1px 0 rgba(255, 255, 255, 1);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25) inset, 0 1px 0 rgba(255, 255, 255, 1);
  }

.form-group.search input {
  height: 33px;
}

::-webkit-input-placeholder {
  color: #c9c9c9;
  font-size: 12px;
}

::-moz-placeholder {
  color: #c9c9c9;
  font-size: 12px;
}

:-ms-input-placeholder {
  color: #c9c9c9;
  font-size: 12px;
}

:-moz-placeholder {
  color: #c9c9c9;
  font-size: 12px;
}

.form-group.search input {
  height: 33px;
  color: #c9c9c9;
  vertical-align: middle;
  padding-right: 7px;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.form-group.search input {
  background: lightblue;
  height: 33px;
  color: #000;
  padding-left: 33px;
  vertical-align: middle;
  padding-right: 7px;
  border: 1px solid #6093a3 !important;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  box-shadow: none;
}

.form-group.search span.input-group-addon {
  background: none;
  border: none;
  left: -8px;
  float: left;
  position: absolute;
  top: 2px;
  z-index: 9;
}

.form-group.search button {
  border: none;
  box-shadow: none;
  background: none;
  color: #c9c9c9;
  outline: none;
}

.btn-add-task a {
  display: block;
  padding: 8px 35px;
  border-radius: 0;
  color: #FFF;
  text-transform: uppercase;
  font-size: 12px;
  height: 33px;
  text-decoration: none;
}

  .btn-add-task a i {
    padding-right: 5px;
  }

.btn-add-task {
  display: inline-block;
  background: #159ad0;
  text-align: center;
  border-radius: 5px;
  float: none;
  vertical-align: top;
}

.my-tasks-table thead tr td {
  background: #b5fa5c;
}

.table .checkbox, .table .radio {
  position: relative;
  display: block;
  margin-top: 0;
  margin-bottom: 0;
  clear: both;
}


  .table .checkbox .cr, .table .radio .cr {
    position: relative;
    display: inline-block;
    border: 1px solid #a9a9a9;
    border-radius: .20em;
    width: 1.5em;
    height: 1.5em;
    float: left;
    margin-right: .5em;
    background: #FFF;
    box-shadow: none;
  }

  .table .checkbox label {
    padding-left: 0;
    margin-bottom: 0;
  }

.h5, h5 {
  font-size: 13px;
  color: #8d9da4;
  margin: 0 0;
}

.table > tbody > tr > td, .table > tbody > tr > th, .table > tfoot > tr > td, .table > tfoot > tr > th, .table > thead > tr > td, .table > thead > tr > th {
  padding: 11px 7px;
  padding: 6px !important;
  vertical-align: middle;
  border: .8px solid #87be40;
  line-height: normal;
  margin-bottom: 2px;
  font-weight: 500;
  word-break: break-word;
}

.hover-disabled tbody tr:hover td {
  background: #fff !important;
}

.table > tbody > tr > td {
  font-size: 14px;
  /*color: #37384e !important;*/
  color: #37384e;
  background: #fff;
}

tr.dta-head td {
  background: none !important;
}

a.dta-sign {
  border: 1px solid #159ad0;
  border-radius: 20px;
  padding: 1px 14px;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 500;
  text-decoration: none;
  transition: all 400ms;
  color: #0b9ece;
}

a.dta-act {
  border: 1px solid #6bbd94;
  border-radius: 20px;
  padding: 1px 14px;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 500;
  text-decoration: none;
  transition: all 400ms;
  color: #6bbd94;
}



a.dta-sign-not {
  border: 1px solid #cd12cb;
  border-radius: 20px;
  padding: 1px 14px;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 500;
  text-decoration: none;
  transition: all 400ms;
  color: #0b9ece;
}

a.dta-act-not {
  border: 1px solid #f06731;
  border-radius: 20px;
  padding: 1px 14px;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 500;
  text-decoration: none;
  transition: all 400ms;
  color: #6bbd94;
}



tr.dta-head td i {
  display: inline-block;
  width: 0px;
  margin: 5px 0;
  padding: 1px 0px;
  height: -6px;
}

  tr.dta-head td i:first-child {
    position: relative;
    top: -1px;
  }


  tr.dta-head td i:first-child {
    position: relative;
    top: -4px;
  }

tr.dta-head td i {
  display: inline-block;
  width: 0px;
  margin: 5px 0;
  padding: 1px 0px;
  height: -6px;
  position: relative;
  top: 2px;
  left: 4px;
  font-size: 12px;
}

.nx-pr li {
  display: inline-block;
  padding: 0 10px;
  display: inline-block;
  text-decoration: none;
  font-weight: 500;
  color: #000;
  padding: 8px 16px;
  padding: 8px 16px;
  padding: 8px 16px;
  background: #f6f6f6;
  border: 1px solid #e3e1e1;
  font-size: 12px;
}

.dataTables_paginate {
  margin-top: 10px;
  padding: 0px;
}

.dataTables_length {
  margin: 0px;
  padding: 0px;
}

.dataTables_info {
  margin-top: 10px;
  padding: 0px;
}

li.paginate_button.current {
  color: #fff;
  padding: 8px 16px;
  background: #2173c1;
  text-decoration: none;
}

li.page-active a {
  color: #9e9e9e;
}

li.li-h a i {
  padding-left: 17px;
  padding-right: 15px;
  font-size: 25px;
  vertical-align: middle;
  position: relative;
  top: -2px;
}

a.prev-dta {
  color: #9e9e9e !important;
}

.email-pge .form-group.search {
  margin-right: 0px;
}

.email-pge .form-group.number-sec label {
  right: -105px;
}

button.btn-edit i {
  transform: rotateY(180deg);
  padding: 3px 2px;
  color: #c9c9c9;
}

td.sm-wd {
  max-width: 30px;
  width: 30px;
}

  td.sm-wd i {
    font-size: 18px;
  }

i.fa.fa-paperclip {
  color: #d1d1d1;
}

i.fa.fa-star {
  color: #fac917;
}

td.mail-addr a {
  text-align: left;
  color: #41adda;
  text-decoration: none;
  font-weight: 500;
}

i.fa.fa-star-o {
  color: #fac917;
}

tr.active-mail td {
  border-bottom: 5px solid #e4e4e4 !important;
}

.tsk-head h4 {
  color: #48b0d0;
  font-size: 25px;
  line-height: 28px;
}

.tsk-head {
  background: white;
  padding: 39px 25px;
  border-radius: 6px;
  text-align: center;
  margin: 0 auto;
  box-shadow: 1px 1px 1px #DDD;
}

  .tsk-head h4 {
    color: #48b0d0;
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    text-align: left;
    margin: 0 auto;
    display: block;
  }

.map-tsk {
  background: #FFF;
  display: block;
  padding: 10px 15px;
  margin-bottom: 5px;
  border-radius: 4px;
  margin-left: -15px;
  box-shadow: 1px 1px 1px #DDD;
  color: #8c8c8c;
}

  .map-tsk:last-child {
    margin-bottom: 0;
  }

.count {
  display: inline-block;
  text-align: right;
  float: right;
  vertical-align: middle;
  font-weight: 500;
  font-size: 18px;
}

  .count.count-1 {
    color: #159ad1;
  }

  .count.count-2 {
    color: #44c7b7;
  }

  .count.count-3 {
    color: #ed7161;
  }

.map-tsk span.icon {
  position: relative;
}

  .map-tsk span.icon i {
    position: absolute;
    bottom: -5px;
    right: 13px;
    color: #26b999;
    font-size: 16px;
  }

    .map-tsk span.icon i.fa.fa-close {
      background: #ed7161;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      font-size: 10px;
      text-align: center;
      color: #FFF;
      line-height: 15px;
    }

.mail-task {
  margin-bottom: 35px;
}

.map-task-in .search {
  margin-right: 0;
}

a.dta-sign:hover {
  background: #159ad0;
  color: #FFF;
}


a.dta-act:hover {
  background: #6bbd94;
  color: #FFF;
}

tr.dta-head td {
  text-transform: uppercase;
}

p {
  /*margin: 0 0 10px;*/
  color: #000;
}




@media(max-width:1199px) {
  .sidebar ol li a, .menu-main ul li a {
    font-size: 15px;
  }
}

.page_status {
  width: 100%;
  text-align: center;
  display: table;
  margin: 40px 0 30px;
}

  .page_status .status_pending, .page_status .status_completed {
    background: white;
    text-align: center;
    display: inline-block;
    padding: 20px 45px;
    border: 1px solid #ececec;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
  }


    .page_status .status_pending:hover {
      box-shadow: inset -5px -10px 0px -5px #3da1ed;
      box-sizing: border-box;
      cursor: pointer;
    }

    .page_status .status_completed:hover {
      box-shadow: inset -5px -10px 0px -5px #51cb30;
      box-sizing: border-box;
      cursor: pointer;
    }

    .page_status .status_pending:hover > .number {
      color: #3da1ed;
    }

    .page_status .status_completed:hover > .number {
      color: #51cb30;
    }

    .page_status .status_pending:hover > .status_txt, .page_status .status_completed:hover > .status_txt {
      color: #7b7b7b;
    }

    .page_status .status_pending.active {
      box-shadow: inset -5px -10px 0px -5px #3da1ed;
      box-sizing: border-box;
      cursor: pointer;
    }

    .page_status .status_completed.active {
      box-shadow: inset -5px -10px 0px -5px #51cb30;
      box-sizing: border-box;
      cursor: pointer;
    }

    .page_status .status_pending.active > .number {
      color: #3da1ed;
    }

    .page_status .status_completed.active > .number {
      color: #51cb30;
    }

    .page_status .status_pending.active > .status_txt, .page_status .status_completed.active > .status_txt {
      color: #7b7b7b;
    }




    .page_status .status_pending .number, .page_status .status_completed .number, .page_status .status_pending .status_txt, .page_status .status_completed .status_txt {
      display: inline-block;
      vertical-align: middle;
    }

    .page_status .status_pending .number, .page_status .status_completed .number {
      font-family: Interstate;
      color: #adadad;
      font-size: 40px;
      font-weight: 500;
    }

    .page_status .status_pending .status_txt, .page_status .status_completed .status_txt {
      color: #adadad;
      font-size: 16px;
      text-align: left;
      margin-left: 15px;
    }

  .page_status .status_pending {
  }

    .page_status .status_pending .number {
    }

    .page_status .status_pending .status_txt {
    }

  .page_status .status_completed {
  }

    .page_status .status_completed .number {
    }

    .page_status .status_completed .status_txt {
    }

-----------------------------------------

.table {
  width: 100% !important;
}

.main-content {
  padding: 0px;
  padding-left: 2%;
}



multiselect
.multiselect .dropdown-menu {
  position: static;
  box-shadow: none;
  display: block;
  float: none;
  max-height: 130px !important;
  overflow: auto;
}

.multiselect-labels a .fa {
  color: #ccc;
}

  .multiselect-labels a .fa:hover {
    color: #fff;
  }

.multiselect-labels .label {
  margin-right: 5px;
  margin-bottom: 5px;
  display: inline-block;
  padding: 4px 6px;
  font-size: 14px;
  font-weight: 400 !important;
}

.multiselect .dropdown-menu > li > a {
  white-space: normal;
}

.multiselect .caret {
  border: none !important;
}

.dropdown-menu label.radio input[type=radio] {
  visibility: hidden;
}

.dropdown-menu label.radio {
  padding-left: 10px;
}

.missed-call-bottom .multiselect-native-select .btn-group button,
.missed-call-bottom .multiselect-native-select .btn-group {
  min-width: 100%;
}

span.multiselect-native-select {
  min-width: 100% !important;
}

.multiselect-native-select .multiselect-container {
  min-width: 100% !important;
}

.table {
  margin-bottom: 0px;
}


Check Box Styles

.checkbox {
  padding-left: 20px;
}

.checkbox label {
  display: inline-block;
  position: relative;
  padding-left: 5px;
}

  .checkbox label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 17px;
    height: 17px;
    left: 0;
    margin-left: -20px;
    border: 1px solid #cccccc;
    border-radius: 3px;
    background-color: #fff;
    -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  }

  .checkbox label::after {
    display: inline-block;
    position: absolute;
    width: 16px;
    height: 16px;
    left: 0;
    top: 0;
    margin-left: -20px;
    padding-left: 3px;
    padding-top: 1px;
    font-size: 11px;
    color: #555555;
  }

.checkbox input[type="checkbox"] {
  opacity: 0;
}

  .checkbox input[type="checkbox"]:focus + label::before {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }

  .checkbox input[type="checkbox"]:checked + label::after {
    font-family: 'FontAwesome';
    content: "\f00c";
  }

  .checkbox input[type="checkbox"]:disabled + label {
    opacity: 0.65;
  }

    .checkbox input[type="checkbox"]:disabled + label::before {
      background-color: #eeeeee;
      cursor: not-allowed;
    }

.checkbox.checkbox-circle label::before {
  border-radius: 50%;
}

.checkbox.checkbox-inline {
  margin-top: 0;
}

.checkbox-primary input[type="checkbox"]:checked + label::before {
  background-color: #428bca;
  border-color: #428bca;
}

.checkbox-primary input[type="checkbox"]:checked + label::after {
  color: #fff;
}

.checkbox-danger input[type="checkbox"]:checked + label::before {
  background-color: #d9534f;
  border-color: #d9534f;
}

.checkbox-danger input[type="checkbox"]:checked + label::after {
  color: #fff;
}

.checkbox-info input[type="checkbox"]:checked + label::before {
  background-color: #5bc0de;
  border-color: #5bc0de;
}

.checkbox-info input[type="checkbox"]:checked + label::after {
  color: #fff;
}

.checkbox-warning input[type="checkbox"]:checked + label::before {
  background-color: #f0ad4e;
  border-color: #f0ad4e;
}

.checkbox-warning input[type="checkbox"]:checked + label::after {
  color: #fff;
}

.checkbox-success input[type="checkbox"]:checked + label::before {
  background-color: #5cb85c;
  border-color: #5cb85c;
}

.checkbox-success input[type="checkbox"]:checked + label::after {
  color: #fff;
}

.radio {
  padding-left: 20px;
}

  .radio label {
    display: inline-block;
    position: relative;
    padding-left: 5px;
  }

    .radio label::before {
      content: "";
      display: inline-block;
      position: absolute;
      width: 17px;
      height: 17px;
      left: 0;
      margin-left: -20px;
      border: 1px solid #cccccc;
      border-radius: 50%;
      background-color: #fff;
      -webkit-transition: border 0.15s ease-in-out;
      -o-transition: border 0.15s ease-in-out;
      transition: border 0.15s ease-in-out;
    }

    .radio label::after {
      display: inline-block;
      position: absolute;
      content: " ";
      width: 11px;
      height: 11px;
      left: 3px;
      top: 3px;
      margin-left: -20px;
      border-radius: 50%;
      background-color: #555555;
      -webkit-transform: scale(0, 0);
      -ms-transform: scale(0, 0);
      -o-transform: scale(0, 0);
      transform: scale(0, 0);
      -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    }

  .radio input[type="radio"] {
    opacity: 0;
  }

    .radio input[type="radio"]:focus + label::before {
      outline: thin dotted;
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px;
    }

    .radio input[type="radio"]:checked + label::after {
      -webkit-transform: scale(1, 1);
      -ms-transform: scale(1, 1);
      -o-transform: scale(1, 1);
      transform: scale(1, 1);
    }

    .radio input[type="radio"]:disabled + label {
      opacity: 0.65;
    }

      .radio input[type="radio"]:disabled + label::before {
        cursor: not-allowed;
      }

  .radio.radio-inline {
    margin-top: 0;
  }

.radio-primary input[type="radio"] + label::after {
  background-color: #428bca;
}

.radio-primary input[type="radio"]:checked + label::before {
  border-color: #428bca;
}

.radio-primary input[type="radio"]:checked + label::after {
  background-color: #428bca;
}

.radio-danger input[type="radio"] + label::after {
  background-color: #d9534f;
}

.radio-danger input[type="radio"]:checked + label::before {
  border-color: #d9534f;
}

.radio-danger input[type="radio"]:checked + label::after {
  background-color: #d9534f;
}

.radio-info input[type="radio"] + label::after {
  background-color: #5bc0de;
}

.radio-info input[type="radio"]:checked + label::before {
  border-color: #5bc0de;
}

.radio-info input[type="radio"]:checked + label::after {
  background-color: #5bc0de;
}

.radio-warning input[type="radio"] + label::after {
  background-color: #f0ad4e;
}

.radio-warning input[type="radio"]:checked + label::before {
  border-color: #f0ad4e;
}

.radio-warning input[type="radio"]:checked + label::after {
  background-color: #f0ad4e;
}

.radio-success input[type="radio"] + label::after {
  background-color: #5cb85c;
}

.radio-success input[type="radio"]:checked + label::before {
  border-color: #5cb85c;
}

.radio-success input[type="radio"]:checked + label::after {
  background-color: #5cb85c;
}

.panel-heading {
  font-size: 17px !important;
  font-weight: 500 !important;
}

.table-rowheader {
  color: #00b3f1;
  font-size: 17px;
  font-weight: 500;
}

.wg-title {
  color: #00BCE6;
  font-size: 15px;
  font-weight: 500;
}











Service Box Styles
.service-box {
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
  perspective: 1000px;
  -webkit-perspective: 1000px;
}

.service-icon {
  width: 100%;
  height: 220px;
  padding: 20px;
  text-align: center;
  transition: all .5s ease;
}

.service-content {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0;
  width: 100%;
  height: 220px;
  padding: 20px;
  text-align: center;
  transition: all .5s ease;
  background-color: #474747;
  background-color: #cd443a;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  -webkit-transform: translateY(110px) rotateX(-90deg);
  -moz-transform: translateY(110px) rotateX(-90deg);
  -ms-transform: translateY(110px) rotateX(-90deg);
  -o-transform: translateY(110px) rotateX(-90deg);
  transform: translateY(110px) rotateX(-90deg);
}

.service-box .service-icon .front-content {
  position: relative;
  top: 80px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

  .service-box .service-icon .front-content i {
    font-size: 28px;
    color: #fff;
    font-weight: 500;
  }

  .service-box .service-icon .front-content h3 {
    font-size: 15px;
    color: #fff;
    text-align: center;
    margin-bottom: 15px;
    text-transform: uppercase;
  }

.service-box .service-content h3 {
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.service-box .service-content p {
  font-size: 13px;
  color: #b1b1b1;
  color: #ffffffd1;
  margin: 0;
}

.yellow {
  background-color: #ffc000;
}

.orange {
  background-color: #fc7f0c;
}

.red {
  background-color: #e84b3a;
  background: linear-gradient(to bottom, rgba(255,255,255,0.15) 0%, rgba(0,0,0,0.15) 100%), radial-gradient(at top center, rgba(156, 100, 139, 0.4) 0%, rgb(25, 68, 146) 120%) #08bfff;
}

.grey {
  background-color: #474747;
}

.service-box:hover .service-icon {
  opacity: 0;
  -webkit-transform: translateY(-110px) rotateX(90deg);
  -moz-transform: translateY(-110px) rotateX(90deg);
  -ms-transform: translateY(-110px) rotateX(90deg);
  -o-transform: translateY(-110px) rotateX(90deg);
  transform: translateY(-110px) rotateX(90deg);
}

.service-box:hover .service-content {
  opacity: 1;
  -webkit-transform: rotateX(0);
  -moz-transform: rotateX(0);
  -ms-transform: rotateX(0);
  -o-transform: rotateX(0);
  transform: rotateX(0);
}

.service-content .tsk-head {
  background: #FF9800;
  padding: 10px;
  border-radius: 6px;
  text-align: center;
  margin: 0 auto;
  box-shadow: 1px 1px 1px #FF9800;
  width: 75px;
  color: #fff;
}

.service-content a, .service-content a:hover {
  color: #fff;
  text-decoration: none;
}

.box {
  padding: 0em;
  width: 29em;
  height: 16em;
  position: relative;
  overflow: hidden;
  top: 1em;
  background-color: #fff;
  box-shadow: 7px 5px 4px rgba(0, 0, 0, 0.5);
  border: 1px solid black;
  border-radius: 2px;
}

.scroll {
  position: absolute;
  -webkit-animation: scroll 15s linear infinite;
  -moz-animation: scroll 15s linear infinite;
  -ms-animation: scroll 15s linear infinite;
  -o-animation: scroll 15s linear infinite;
  animation: scroll 15s linear infinite;
}

hr.style-eight {
  height: 10px;
  border: 1;
  box-shadow: inset 0 9px 9px -3px rgba(11, 99, 184, 0.8);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: none;
}


@media (max-width: 43em) {
  body {
    --wide: 0;
  }
}

@media (min-width: 270px) {
  body {
    --narr: 0;
  }
}

.btn-danger {
  color: #fff;
  background: #d9534f !important;
  border-color: #d43f3a;
}

.dropdown-menu {
  font-size: 13px !important;
}

Content Slider
.content-slide {
  background-color: #0672a3;
  background-color: rgba(23,166,152,1);
  height: 225px !important;
}

.content-slide {
  width: 100%;
  height: 100%;
  margin: 0;
}

.content-slide {
  font-family: 'Lato', sans-serif !important;
  font-size: 13pt !important;
  font-weight: 500 !important;
}

#myPageContent, section {
  height: 100% !important;
  width: 100% !important;
}

#textSlider.row {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100% !important;
}

#textSlider div {
  color: #FFF;
  font-family: 'Lato', sans-serif;
  text-transform: uppercase;
  font-size: 30px;
}

#textSlider p {
  color: #fff;
  font-size: 30px;
}

.iamCol {
  text-align: right;
  float: left;
  font-weight: 500;
  font-size: 30pt;
}

  .iamCol p:nth-child(2) {
    margin-top: -20pt !important;
  }

.slideCol {
  text-align: left;
  overflow: hidden;
  font-weight: 500;
  font-size: 70pt;
  display: block;
  white-space: nowrap;
}

  .slideCol p {
    margin: 0px !important;
  }

.scroller {
  height: 70pt;
  line-height: 70pt;
  overflow: hidden;
}

  .scroller .inner {
    animation: 10s normal infinite running scroll;
  }

@keyframes scroll {
  0% {
    margin-top: 0px;
  }

  15% {
    margin-top: 0px;
  }

  25% {
    margin-top: -70pt;
  }

  40% {
    margin-top: -70pt;
  }

  50% {
    margin-top: -140pt;
  }

  65% {
    margin-top: -140pt;
  }

  75% {
    margin-top: -210pt;
  }

  90% {
    margin-top: -210pt;
  }

  100% {
    margin-top: 0px;
  }
}



.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20px {
  margin-bottom: 20px !important;
}

.controls-passed {
  background: green;
  background: repeating-linear-gradient(135deg, #2fc62f 50%, #077a07 100%);
  background: #2173c1;
  color: #fff !important;
  padding: 4%;
  border-radius: 2em;
}

.controls-passed-green {
  background: green;
  color: #fff !important;
  padding: 4%;
  border-radius: 2em;
}

.controls-default {
  background: #005eb8de;
  background: repeating-linear-gradient(135deg, #338ee5de 50%, #005eb8de 100%);
  background: #2173c1;
  color: #fff !important;
  padding: 4%;
  border-radius: 2em;
}

.controls-failed {
  background: red;
  background: repeating-linear-gradient(135deg, #f33a3a 50%, #9d0303 100%);
  background: #2173c1;
  color: rgb(255, 0, 0) !important;
  padding: 4%;
  border-radius: 2em;
}

.controls-failed-gray {
  background: #2173c1;
  color: rgb(255, 0, 0) !important;
  padding: 4%;
  border-radius: 2em;
}

.col-white {
  color: white !Important;
}

.note-toolbar.panel-heading {
  background: #005eb8de;
}

.text-upper {
  text-transform: uppercase !important;
}

.text-lower {
  text-transform: lowercase !important;
}

.text-cnone {
  text-transform: none !important;
}

.text-dark-blue {
  color: #88be42;
}



Radio Button Style
label.btn span {
  font-size: 1em;
  vertical-align: super;
}

label input[type="radio"] ~ i.fa.fa-circle-o {
  color: #c8c8c8;
  display: inline;
}

label input[type="radio"] ~ i.fa.fa-dot-circle-o {
  display: none;
}

label input[type="radio"]:checked ~ i.fa.fa-circle-o {
  display: none;
}

label input[type="radio"]:checked ~ i.fa.fa-dot-circle-o {
  color: #7AA3CC;
  display: inline;
}

label:hover input[type="radio"] ~ i.fa {
  color: #7AA3CC;
}

label input[type="checkbox"] ~ i.fa.fa-square-o {
  color: #c8c8c8;
  display: inline;
}

label input[type="checkbox"] ~ i.fa.fa-check-square-o {
  display: none;
}

label input[type="checkbox"]:checked ~ i.fa.fa-square-o {
  display: none;
}

label input[type="checkbox"]:checked ~ i.fa.fa-check-square-o {
  color: #7AA3CC;
  display: inline;
}

label:hover input[type="checkbox"] ~ i.fa {
  color: #7AA3CC;
}

div[data-toggle="buttons"] label.active {
  color: #7AA3CC;
}

div[data-toggle="buttons"] label {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 1em;
  text-align: left;
  white-space: nowrap;
  vertical-align: top;
  cursor: pointer;
  background-color: none;
  border: 0px solid #c8c8c8;
  border-radius: 3px;
  color: #c8c8c8;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

  div[data-toggle="buttons"] label:hover {
    color: #7AA3CC;
  }

  div[data-toggle="buttons"] label:active, div[data-toggle="buttons"] label.active {
    -webkit-box-shadow: none;
    box-shadow: none;
  }



Index Hexagon

.heading-page {
  text-transform: uppercase;
  font-size: 43px;
  font-weight: bolder;
  letter-spacing: 3px;
  color: white;
}

.btn-primary {
  background-color: #FA6862;
  border: 0;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  height: 48px;
  line-height: 50px;
  padding: 0 42px;
  text-transform: uppercase;
  outline: 0;
  font-style: normal;
  font-weight: 500;
  font-family: inherit;
  padding: 0 35px;
  font-size: 13px;
  line-height: 48px;
  height: 48px;
  text-transform: capitalize;
  letter-spacing: 1px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 25px;
  border: 2px solid #e6e6e6;
  color: #fff;
  background-color: transparent;
  background: repeating-linear-gradient(135deg, #2faee7 50%, #0770c4 100%);
  -webkit-transition: color .2s ease-out,background-color .2s ease-out,border-color .2s ease-out;
  transition: color .2s ease-out,background-color .2s ease-out,border-color .2s ease-out;
  -webkit-box-sizing: initial;
  -moz-box-sizing: initial;
  box-sizing: initial;
}

  .btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary:active:focus {
    background-color: #f9423a;
  }

.btn-border {
  border: 1px solid #d7d8db;
  display: inline-block;
  padding: 7px;
}


* CSS Helper Class

.clear:before, .clear:after {
  content: " ";
  display: table;
}

.clear:after {
  clear: both;
}

.pt-table {
  display: table;
  width: 100%;
  height: -webkit-calc(100vh - 4px);
  height: -moz-calc(100vh - 4px);
  height: calc(100vh - 4px);
}

.pt-tablecell {
  display: table-cell;
  vertical-align: middle;
}

.overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.relative {
  position: relative;
}

.primary,
.link:hover {
  color: #FA6862;
}

.no-gutter {
  margin-left: 0;
  margin-right: 0;
}

  .no-gutter > [class^="col-"] {
    padding-left: 0;
    padding-right: 0;
  }

.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}

.flex-middle {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}

.space-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
}

.nicescroll-cursors {
  background: #FA6862 !important;
}

.preloader {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}

  .preloader.active.hidden {
    display: none;
  }

.loading-mask {
  background-color: #FA6862;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 20%;
  -webkit-transition: all 0.6s cubic-bezier(0.61, 0, 0.6, 1) 0s;
  -moz-transition: all 0.6s cubic-bezier(0.61, 0, 0.6, 1) 0s;
  -o-transition: all 0.6s cubic-bezier(0.61, 0, 0.6, 1) 0s;
  transition: all 0.6s cubic-bezier(0.61, 0, 0.6, 1) 0s;
}

  .loading-mask:nth-child(2) {
    left: 20%;
    -webkit-transition-delay: 0.1s;
    -moz-transition-delay: 0.1s;
    -o-transition-delay: 0.1s;
    transition-delay: 0.1s;
  }

  .loading-mask:nth-child(3) {
    left: 40%;
    -webkit-transition-delay: 0.2s;
    -moz-transition-delay: 0.2s;
    -o-transition-delay: 0.2s;
    transition-delay: 0.2s;
  }

  .loading-mask:nth-child(4) {
    left: 60%;
    -webkit-transition-delay: 0.3s;
    -moz-transition-delay: 0.3s;
    -o-transition-delay: 0.3s;
    transition-delay: 0.3s;
  }

  .loading-mask:nth-child(5) {
    left: 80%;
    -webkit-transition-delay: 0.4s;
    -moz-transition-delay: 0.4s;
    -o-transition-delay: 0.4s;
    transition-delay: 0.4s;
  }

.preloader.active.done {
  z-index: 0;
}

.preloader.active .loading-mask {
  width: 0;
}

------------------------------------------------
Start Styling
-------------------------------------------------

.page-close {
  font-size: 30px;
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 100;
}

.page-title {
  margin-bottom: 75px;
}

  .page-title img {
    margin-bottom: 20px;
  }

  .page-title h2 {
    font-size: 68px;
    margin-bottom: 25px;
    position: relative;
    z-index: 0;
    font-weight: 900;
    text-transform: uppercase;
  }

  .page-title p {
    font-size: 16px;
  }

  .page-title .title-bg {
    color: rgba(30, 37, 48, 0.07);
    font-size: 158px;
    left: 0;
    letter-spacing: 10px;
    line-height: 0.7;
    position: absolute;
    right: 0;
    top: 50%;
    z-index: -1;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }

.section-title {
  margin-bottom: 20px;
}

  .section-title h3 {
    display: inline-block;
    position: relative;
  }

    .section-title h3::before, .section-title h3::after {
      content: "";
      height: 2px;
      position: absolute;
      bottom: 8px;
      left: -webkit-calc(100% + 14px);
      left: -moz-calc(100% + 14px);
      left: calc(100% + 14px);
    }

    .section-title h3::before {
      background-color: #1e2530;
      width: 96px;
      bottom: 14px;
    }

    .section-title h3::after {
      background-color: #FA6862;
      width: 73px;
    }

  .section-title.light h3 {
    color: #fff;
  }

    .section-title.light h3::before {
      background-color: #fff;
    }

------------------------------------------------
Home Page
-------------------------------------------------

.hexagon-item:first-child {
  margin-left: 0;
}

.page-home {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  vertical-align: middle;
}

  .page-home .overlay {
    background-color: rgba(14, 17, 24, 0.97);
  }

End of container
.hexagon-item {
  cursor: pointer;
  width: 200px;
  height: 173.20508px;
  float: left;
  margin-left: -29px;
  z-index: 0;
  position: relative;
  -webkit-transform: rotate(30deg);
  -moz-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  -o-transform: rotate(30deg);
  transform: rotate(30deg);
}

.hexagon-item:first-child {
  margin-left: 0;
}

.hexagon-item:hover {
  z-index: 1;
}

  .hexagon-item:hover .hex-item:last-child {
    opacity: 1;
    -webkit-transform: scale(1.3);
    -moz-transform: scale(1.3);
    -ms-transform: scale(1.3);
    -o-transform: scale(1.3);
    transform: scale(1.3);
  }

  .hexagon-item:hover .hex-item:first-child {
    opacity: 1;
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }

    .hexagon-item:hover .hex-item:first-child div:before,
    .hexagon-item:hover .hex-item:first-child div:after {
      height: 5px;
    }

  .hexagon-item:hover .hex-item div::before,
  .hexagon-item:hover .hex-item div::after {
    background-color: #ff0037;
  }

  .hexagon-item:hover .hex-content svg {
    -webkit-transform: scale(0.97);
    -moz-transform: scale(0.97);
    -ms-transform: scale(0.97);
    -o-transform: scale(0.97);
    transform: scale(0.97);
  }

.page-home .hexagon-item:nth-last-child(1),
.page-home .hexagon-item:nth-last-child(2),
.page-home .hexagon-item:nth-last-child(3) {
  -webkit-transform: rotate(30deg) translate(87px, -80px);
  -moz-transform: rotate(30deg) translate(87px, -80px);
  -ms-transform: rotate(30deg) translate(87px, -80px);
  -o-transform: rotate(30deg) translate(87px, -80px);
  transform: rotate(30deg) translate(87px, -80px);
}

.hex-item {
  position: absolute;
  top: 0;
  left: 50px;
  width: 100px;
  height: 173.20508px;
}

  .hex-item:first-child {
    z-index: 0;
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    transform: scale(0.9);
    -webkit-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    -moz-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    -o-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .hex-item:last-child {
    transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
    z-index: 1;
  }

  .hex-item div {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    width: 100px;
    height: 173.20508px;
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center;
  }

    .hex-item div::before, .hex-item div::after {
      background-color: #1e2530;
      content: "";
      position: absolute;
      width: 100%;
      height: 3px;
      -webkit-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
      -moz-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
      -o-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
      transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
    }

    .hex-item div:before {
      top: 0;
    }

    .hex-item div:after {
      bottom: 0;
    }

    .hex-item div:nth-child(1) {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    .hex-item div:nth-child(2) {
      -webkit-transform: rotate(60deg);
      -moz-transform: rotate(60deg);
      -ms-transform: rotate(60deg);
      -o-transform: rotate(60deg);
      transform: rotate(60deg);
    }

    .hex-item div:nth-child(3) {
      -webkit-transform: rotate(120deg);
      -moz-transform: rotate(120deg);
      -ms-transform: rotate(120deg);
      -o-transform: rotate(120deg);
      transform: rotate(120deg);
    }

.hex-content {
  color: #fff;
  display: block;
  height: 180px;
  margin: 0 auto;
  position: relative;
  text-align: center;
  transform: rotate(-30deg);
  width: 156px;
}

  .hex-content .hex-content-inner {
    left: 50%;
    margin: -3px 0 0 2px;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .hex-content .icon {
    display: block;
    font-size: 36px;
    line-height: 30px;
    margin-bottom: 11px;
  }

  .hex-content .title {
    display: block;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 24px;
    text-transform: uppercase;
  }

  .hex-content svg {
    left: -7px;
    position: absolute;
    top: -13px;
    transform: scale(0.87);
    z-index: -1;
    -webkit-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
    -moz-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
    -o-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  }

  .hex-content:hover {
    color: #fff;
  }

.page-home .hexagon-item:nth-last-child(1), .page-home .hexagon-item:nth-last-child(2), .page-home .hexagon-item:nth-last-child(3) {
  -webkit-transform: rotate(30deg) translate(87px, -80px);
  -moz-transform: rotate(30deg) translate(87px, -80px);
  -ms-transform: rotate(30deg) translate(87px, -80px);
  -o-transform: rotate(30deg) translate(87px, -80px);
  transform: rotate(30deg) translate(87px, -80px);
}

------------------------------------------------
Welcome Page
-------------------------------------------------
.author-image-large {
  position: absolute;
  right: 0;
  top: 0;
}

.author-image-large img {
  height: -webkit-calc(100vh - 4px);
  height: -moz-calc(100vh - 4px);
  height: calc(100vh - 4px);
}


@media (min-width: 1200px) {
  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }
}

@media (min-width: 1200px) {
  .col-lg-8 {
    width: 66.66666667%;
  }
}

.hexagon-item:first-child {
  margin-left: 0;
}

.pt-table.desktop-768 .pt-tablecell {
  padding-bottom: 110px;
  padding-top: 60px;
}



.hexagon-item:hover .icon i {
  color: #ff0037;
  transition: 0.6s;
}


.hexagon-item:hover .title {
  -webkit-animation: focus-in-contract 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: focus-in-contract 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

*************************

@-webkit-keyframes focus-in-contract {
  0%

{
  letter-spacing: 1em;
  -webkit-filter: blur(12px);
  filter: blur(12px);
  opacity: 0;
}

100% {
  -webkit-filter: blur(0px);
  filter: blur(0px);
  opacity: 1;
}

}

@keyframes focus-in-contract {
  0% {
    letter-spacing: 1em;
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }

  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}





@media only screen and (max-width: 767px) {
  .hexagon-item {
    float: none;
    margin: 0 auto 50px;
  }

    .hexagon-item:first-child {
      margin-left: auto;
    }

  .page-home .hexagon-item:nth-last-child(1), .page-home .hexagon-item:nth-last-child(2), .page-home .hexagon-item:nth-last-child(3) {
    -webkit-transform: rotate(30deg) translate(0px, 0px);
    -moz-transform: rotate(30deg) translate(0px, 0px);
    -ms-transform: rotate(30deg) translate(0px, 0px);
    -o-transform: rotate(30deg) translate(0px, 0px);
    transform: rotate(30deg) translate(0px, 0px);
  }
}

.pad-0 {
  padding: 0px;
}

.autoht {
  height: auto !important;
  min-height: 50px;
  padding: 12px;
}

span.form-control.ng-binding.autoht {
  height: auto !important;
}

table.table.table-hover.table-bordered.table-responsive th {
  background: #005eb8de;
  color: #fff;
  padding: 5px;
}

.sticky-col {
  position: -webkit-sticky;
  position: sticky;
  left: 0px;
}

table.table.table-hover.sticky-header thead tr td {
  position: sticky;
  top: 0;
  z-index: 1;
}

table.table.table-hover.sticky-header thead tr td {
  position: sticky;
  top: 0;
  z-index: 1;
}

table.table.hover-disabled.sticky-header thead tr td {
  position: sticky;
  top: 0;
  z-index: 1;
}

table.table.hover-disabled.sticky-header thead tr td {
  position: sticky;
  top: 0;
  z-index: 1;
}

.table-responsive {
  display: inline-table !important;
}

.table tbody tr:hover td {
  background: #dcdcdc;
}

thead tr td {
  background: #87be40;
  color: #000;
  padding: 5px;
  text-align: center;
}

.table thead tr th {
  background: #87be40;
  color: #fff;
  text-align: center;
  font-size: 14px;
}

.srch-space {
  margin-top: -5px !important;
}


.si-mid-panel {
  background-color: #FFFFFF;
  border: solid 1px #DCE1E5;
  border-left-style: none;
  height: 1070px;
  overflow-y: scroll;
  padding-left: 14px;
  border-radius: 0px 4px 4px 4px;
  -moz-border-radius: 0px 4px 4px 4px;
  -webkit-border-radius: 0px 4px 4px 4px;
  -webkit-box-shadow: 0px 0px 18px 2px rgba(0,0,0,0.05);
  -moz-box-shadow: 0px 0px 18px 2px rgba(0,0,0,0.05);
  box-shadow: 0px 0px 18px 2px rgba(0,0,0,0.05);
  margin-right: 14px;
  padding-top: 26px;
}

.sc-banner-list li:hover a {
  color: #fff;
}

.sc-banner-list li:hover {
  background: #005eb8;
  transition: all 0.4s ease;
  transform: scaleX(1.1);
}

.sc-banner-list li i {
  font-size: 16px;
  padding: 6px;
  color: #3F51B5;
}

.sc-banner-list li:hover i {
  color: #fff;
}

.sc-banner-list {
  list-style: none;
  word-break: break-word;
}

  .sc-banner-list a {
    color: #000;
    font-weight: 600;
    cursor: pointer;
  }

  .sc-banner-list li {
    border: 1px solid #c0bfbf52;
    margin: 10px 0px;
    padding: 10px 10px;
  }

.mid-banner-list {
  list-style: outside;
  margin-left: 18px;
}

.si-right-panel {
  background-color: #FFFFFF;
  border: solid 1px #DCE1E5;
  border-left-style: none;
  height: 1070px;
  overflow-y: scroll;
  padding-left: 50px;
  border-radius: 0px 4px 4px 4px;
  -moz-border-radius: 0px 4px 4px 4px;
  -webkit-border-radius: 0px 4px 4px 4px;
  -webkit-box-shadow: 0px 0px 18px 2px rgba(0,0,0,0.05);
  -moz-box-shadow: 0px 0px 18px 2px rgba(0,0,0,0.05);
  box-shadow: 0px 0px 18px 2px rgba(0,0,0,0.05);
  padding-top: 26px;
}

.si-end-panel {
  padding: 0px 0px 0px 12px;
}

/*.tab-content > div {
  margin-top: 26px;
}

Formato del tab activo
.tabs-left > .nav-tabs .active > a,
.tabs-left > .nav-tabs .active > a:hover,
.tabs-left > .nav-tabs .active > a:focus,
.tabs-left > .nav-tabs .active div,
.tabs-left > .nav-tabs .active div:hover,
.tabs-left > .nav-tabs .active div:focus {
  background-color: #005eb8;
  background-color: #004b93;
  border-bottom-style: none;
  border-left-style: none;
  border-bottom: 1px solid #DCE1E5;
  border-left: 1px solid #DCE1E5;
  border-bottom-left-radius: 0px;
  border-right-style: none;
  margin-right: -1px;
  -webkit-box-shadow: -4px 0px 18px -1px rgba(0,0,0,0.05);
  -moz-box-shadow: -4px 0px 18px -1px rgba(0,0,0,0.05);
  box-shadow: -4px 0px 18px -1px rgba(0,0,0,0.05);
  color: #fff;
}
*/
.active span.account-type {
  color: #fff;
}

/*Formato de los tabs en general
.tabs-left > .nav-tabs > li:nth-child(1) > a,
.tabs-left > .nav-tabs > li:nth-child(1) > a:hover,
.tabs-left > .nav-tabs > li:nth-child(1) > a:focus,
.tabs-left > .nav-tabs > li:nth-child(1) > div,
.tabs-left > .nav-tabs > li:nth-child(1) > div:hover,
.tabs-left > .nav-tabs > li:nth-child(1) > div:focus {
  cursor: pointer;
  border-top-left-radius: 4px;
}

.tabs-left > .nav-tabs > li.active:nth-child(1) > a,
.tabs-left > .nav-tabs > li.active:nth-child(1) > a:hover,
.tabs-left > .nav-tabs > li.active:nth-child(1) > a:focus,
.tabs-left > .nav-tabs > li.active:nth-child(1) > div,
.tabs-left > .nav-tabs > li.active:nth-child(1) > div:hover,
.tabs-left > .nav-tabs > li.active:nth-child(1) > div:focus {
  border-top-left-radius: 4px;
  border-bottom-style: none;
  border-left: 1px solid #DCE1E5;
}

.tabs-left > .nav-tabs > li > a,
.tabs-left > .nav-tabs > li > div {
  display: table;
  border: solid 1px transparent;
  margin-right: -1px;
  margin-bottom: 0;
  padding-bottom: 0;
  height: 45px;
  border: solid 1px #DCE1E5;
  border-radius: 0px;
  color: #000;
}

Style of the div element acting as tab content
.tabbable.tabs-left > .nav-tabs > li > div > div {
  display: table-cell;
  vertical-align: middle;
  padding: 0px 12px 0px 12px;
  min-width: 230px;
  max-width: 230px;
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
}
*/
ul.nav-tabs.nav-pills.nav.flex-column li {
  width: 16rem;
}

.pan-back-light {
  /*background: #2173c161;*/
  background: #9e9e9e61;
  color: #fff;
}

.tooltip {
  position: relative;
  display: contents;
  border-bottom: 1px dotted black;
}

  .tooltip .tooltiptext {
    visibility: hidden;
    width: auto;
    background-color: #005eb8;
    color: #fff;
    text-align: left;
    /*border-radius: 6px;*/
    padding: 8px 8px;
    position: absolute;
    z-index: 3;
    top: -271%;
    left: 26%;
    margin-left: 10px;
  }
  /*
    .tooltip .tooltiptext::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 100%;
      margin-top: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent black transparent transparent;
    }*/

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

.my-tasks {
  background: #87be40;
  color: rgb(255, 255, 255);
  font-size: 14px;
  height: 40px;
  font-weight: 700;
  overflow: hidden;
  transform: rotate( 90deg );
  transform-origin: right top;
  position: fixed;
  top: 245px;
  right: 0px;
  white-space: nowrap;
  width: 85px;
}

  .my-tasks span {
    display: inline-block;
    transform: rotate( 180deg );
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    height: 100%;
  }

.help-fixed-c {
  background: #88be42;
  color: rgb(255, 255, 255);
  font-size: 14px;
  height: 40px;
  font-weight: 700;
  overflow: hidden;
  transform: rotate( 90deg );
  transform-origin: right top;
  position: fixed;
  bottom: 0%;
  right: 0px;
  white-space: nowrap;
  width: 70px;
  display: none !important;
}

  .help-fixed-c span {
    display: inline-block;
    transform: rotate( 180deg );
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    height: 100%;
  }
/*.help-fixed-c {
  position: fixed;
  right: 1.5%;
  bottom: 20px;
}

  .help-fixed-c i {
    background: #a9a9a9;
    border-radius: 50px;
    height: 70px;
    width: 70px;
    padding-top: 20px;
    color: #000;
    font-weight: 500;
    border: 1px solid black;
  }
*/
.btn-gray-redtxt {
  background: #a9a9a9 !important;
  border-color: #a9a9a9;
  font-weight: 600;
  border-radius: 0px !important;
  cursor: pointer;
}

  .btn-gray-redtxt label {
    color: #000 !important;
    cursor: pointer;
  }

.btn-gray-greentxt {
  color: green !important;
  background: #f1eded !important;
  border-color: #f1eded;
}

.btn-success-blue {
  color: #fff;
  background: #87be40;
  border-color: #87be40;
  opacity: 1 !important;
  border-radius: 0px !important;
}

  .btn-success-blue label {
    color: #fff;
  }

.btn-success-green {
  color: #fff !important;
  background: green !important;
  border-color: green !important;
  opacity: 1 !important;
}

button[aria-label="Close"]:focus {
  outline: none;
}

.selected-item {
  border-radius: unset;
  margin: 2px;
}

.wper-40 {
  width: 40%;
}

.wper-20 {
  width: 20%;
}

.navbar-light .navbar-nav .nav-link {
  color: #000;
}






/* Custom Nav Tabs */
/*.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff !important;
  background-color: #007bff;
}
*/
ul.nav-tabs.nav-pills.nav.flex-column li {
  width: 16rem;
  background: #f1eded;
  border: 1px solid #e0cfcf;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff !important;
  background-color: #000;
  border-radius: unset;
}

.nav-pills .nav-item a {
  color: #fff;
  width: 100%;
}

ul.nav-tabs.nav-pills.nav.flex-column li a {
  color: #88be42;
}

/*.nav-item:hover .nav-link {
  color: #fff !important;
}
*/
/*.nav-item:hover {
  color: #88be42;
  padding-left: 8px !important;
  padding-right: 8px !important;
}*/
.nav-item:hover {
  background: #87be40;
  color: #fff;
  padding-left: 8px !important;
  padding-right: 8px !important;
}
/*.navbar-nav .nav-item:hover a {
  color: #fff !important;
}*/

.nav-item.active {
  background: #87be40;
  color: #fff;
  padding-left: 20px !important;
  padding-right: 20px !important;
  line-height: 1.4;
}

  .nav-item.active a {
    color: #fff !important;
  }

.font-18 {
  font-size: 18px;
}

.primary-btn {
  letter-spacing: 1px;
  height: 50px;
  padding: 9px 10px 10px 10px;
  color: #fff;
  background-color: #88be42;
  border-style: solid;
  border-width: 1px;
  border-color: var(--e-global-color-322b4d9);
  /* box-shadow: 0 0 5px 0 rgb(0 0 0 / 50%); */
  font-size: 16px;
  width: 100%;
}

  .primary-btn:hover {
    background: #000;
  }

input[type="file" i].primary-btn {
  cursor: pointer;
}

.secondary-btn {
  letter-spacing: 1px;
  height: 50px;
  padding: .375rem .75rem;
  color: #000;
  background-color: #a9a9a9;
  /* border-style: solid; */
  /* border-width: 1px; */
  /* border-color: black; */
  font-size: 16px;
  min-width: 94.8px;
  border: 1px solid #000;
  width: auto;
}

  .secondary-btn:hover {
    background: #87be40;
    color: #fff !important;
    text-decoration: none;
  }

  .secondary-btn:focus {
    border-radius: 0px !important;
    outline: unset !important;
  }



.toggle-btn {
  letter-spacing: 1px;
  height: 50px;
  padding: 10px;
  color: #fff !important;
  font-weight: 400;
  background-color: #a9a9a9;
  font-size: 16px;
  min-width: 94.8px;
  border: 1px solid #000;
  width: 230px;
}

  .toggle-btn:hover {
    background: #87be40;
    outline: none !important;
    color: #fff !important;
    text-decoration: none;
  }

button.toggle-btn.active {
  background: #88be42 !important;
}

.toggle-btn:focus {
  outline: none !important;
}
/*.help-panel {
  position: fixed;
  top: 32%;
  background: #F5F5F5;
  right: 1%;
  width: 25%;
}*/
.help-panel {
  position: fixed;
  bottom: 40px;
  right: 70px;
  background: #fff;
  /* margin-right: 20px; */
  z-index: 1000;
  width: 325px !important;
  border: 2px solid #88be42;
  border-radius: 0px !important;
}

  .help-panel .panel-header {
    border-radius: 0px !important;
    border: 2px solid #88be42;
    height: 62.5px;
    width: 325px;
    background: #88be42;
    display: flex;
    align-items: baseline;
  }

    .help-panel .panel-header h4 {
      font-weight: 500;
      font-size: 16px;
      vertical-align: middle;
      align-items: center;
      height: 100%;
      padding: 10px 0px;
      color: #fff;
      width: 90%;
    }

    .help-panel .panel-header .close {
      color: #fff;
    }

.primary-panel {
  border: 1px solid #a9a9a9;
  border-radius: 0 !important;
  height: 212px;
  /*overflow-y: scroll;*/
}

  .primary-panel .panel-body {
    height: 180px;
    padding: 15px;
    overflow-y: auto;
    line-height: 1.5;
    max-height: 220px;
    background: #fff;
  }

  .primary-panel .panel-header {
    /*border-radius: 0px !important;
  border: 2px solid #88be42;*/
    height: 30px;
    /*background: #88be42;*/
    align-items: baseline;
  }

    .primary-panel .panel-header h4 {
      font-weight: 500;
      font-size: 16px;
      vertical-align: middle;
      align-items: center;
      height: 100%;
      padding: 5px 0;
      color: #fff;
      background: #000;
    }

  .primary-panel .panel-body marquee p {
    font-size: 16px !important;
    padding: 0px;
  }
/*.help-panel {
  position: absolute;
  background: #fff;
  bottom: 43px;
  margin-right: 20px;
  z-index: 1000;
  width: 426px !important;
  filter: drop-shadow(0px 0px #dcdcdc) drop-shadow(0px 0px #dcdcdc) drop-shadow(0px 0px #dcdcdc) drop-shadow(0px 0px #dcdcdc) drop-shadow(0px 0px 1px #dcdcdc) drop-shadow(0px 0px 1px #dcdcdc);
}

  .help-panel:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 88%;
    height: 20px;
    width: 51px;
    background: inherit;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%);
  }*/
.user-fixed-c {
  background: #88be42;
  color: rgb(255, 255, 255);
  font-size: 14px;
  height: 40px;
  font-weight: 700;
  overflow: hidden;
  transform: rotate( 90deg );
  transform-origin: right top;
  position: fixed;
  bottom: 30%;
  right: 0px;
  white-space: nowrap;
  width: 70px;
  display: none;
}

  .user-fixed-c span {
    display: inline-block;
    transform: rotate( 180deg );
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    height: 100%;
  }

.admin-fixed-c {
  background: #88be42;
  color: rgb(255, 255, 255);
  font-size: 14px;
  height: 40px;
  font-weight: 700;
  overflow: hidden;
  transform: rotate( 90deg );
  transform-origin: right top;
  position: fixed;
  bottom: 45%;
  right: 0px;
  white-space: nowrap;
  width: 70px;
  display: none;
}

  .admin-fixed-c span {
    display: inline-block;
    transform: rotate( 180deg );
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    height: 100%;
  }

.arrow-fixed-c {
  background: #88be42;
  color: rgb(255, 255, 255);
  font-size: 14px;
  height: 40px;
  font-weight: 700;
  overflow: hidden;
  transform: rotate( 90deg );
  transform-origin: right top;
  position: fixed;
  bottom: 45%;
  right: 0px;
  white-space: nowrap;
  width: 70px;
}

  .arrow-fixed-c span {
    display: inline-block;
    transform: rotate( 180deg );
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    height: 100%;
  }

.admin-shortcuts {
  position: fixed;
  bottom: 40px;
  right: 70px;
  background: #fff;
  /* margin-right: 20px; */
  z-index: 1000;
  width: 325px !important;
  border: 2px solid #88be42;
  border-radius: 0px !important;
  /*background: #fff;
  position: absolute;
  height: -webkit-fill-available;
  bottom: 363px;
  margin-right: 20px;
  z-index: 1000;
  width: 426px !important;
  filter:drop-shadow(0px 0px #dcdcdc) drop-shadow(0px 0px #dcdcdc) drop-shadow(0px 0px #dcdcdc) drop-shadow(0px 0px #dcdcdc) drop-shadow(0px 0px 1px #dcdcdc) drop-shadow(0px 0px 1px #dcdcdc);*/
}

  .admin-shortcuts .panel-header {
    border-radius: 0px !important;
    border: 2px solid #88be42;
    height: 62.5px;
    width: 325px;
    background: #88be42;
    display: flex;
    align-items: baseline;
  }

    .admin-shortcuts .panel-header .close {
      color: #fff;
    }

    .admin-shortcuts .panel-header h4 {
      font-weight: 500;
      font-size: 16px;
      vertical-align: middle;
      align-items: center;
      height: 100%;
      padding: 10px 0px;
      color: #fff;
      width: 90%;
    }

  .admin-shortcuts .panel-body {
    padding: 1.25rem;
  }
  /*  .admin-shortcuts:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 88%;
    height: 20px;
    width: 51px;
    background: inherit;
    clip-path: polygon(0 0, 100% 0, 100% 100%);
  }*/
  .admin-shortcuts .form-control {
    background: #f5f5f5;
  }

.user-shortcuts .form-control {
  background: #f5f5f5;
}

.user-shortcuts .panel-header {
  border-radius: 0px !important;
  border: 2px solid #88be42;
  height: 62.5px;
  width: 325px;
  background: #88be42;
  display: flex;
  align-items: baseline;
}

  .user-shortcuts .panel-header h4 {
    font-weight: 500;
    font-size: 16px;
    vertical-align: middle;
    align-items: center;
    height: 100%;
    padding: 10px 0px;
    color: #fff;
    width: 90%;
  }

  .user-shortcuts .panel-header .close {
    color: #fff;
  }

.user-shortcuts {
  position: fixed;
  bottom: 40px;
  right: 70px;
  background: #fff;
  /* margin-right: 20px; */
  z-index: 1000;
  width: 325px !important;
  border: 2px solid #88be42;
  border-radius: 0px !important;
  /*background: #fff;
  position: absolute;
  bottom: 263px;
  margin-right: 20px;
  z-index: 1000;
  width: 426px !important;
  filter: drop-shadow(0px 0px #dcdcdc) drop-shadow(0px 0px #dcdcdc) drop-shadow(0px 0px #dcdcdc) drop-shadow(0px 0px #dcdcdc) drop-shadow(0px 0px 1px #dcdcdc) drop-shadow(0px 0px 1px #dcdcdc);*/
}

  .user-shortcuts .panel-body {
    padding: 1.25rem;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 552px;
  }
/*
  .user-shortcuts:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 88%;
    height: 20px;
    width: 51px;
    background: inherit;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%);
  }
*/
/*.sub-nav {
  width: 1385px !important;
}*/
.form-content-panel {
  border: 5px solid #87be40;
  padding: 20px;
}

.font-x-larger {
  font-size: 24px !important;
}

#sub-nav li a {
  font-size: 18px;
}

.nav-tabs li a {
  font-size: 18px;
}

.mt-60px {
  margin-top: 60px;
}

.mt-100px {
  margin-top: 100px;
}

.mt-150px {
  margin-top: 150px;
}

.mt-50px {
  margin-top: 50px;
}

.mt-20px {
  margin-top: 20px;
}

.mt-30px {
  margin-top: 30px;
}

.mb-100px {
  margin-bottom: 100px;
}

.ml-100px {
  margin-left: 100px;
}

.ml-20px {
  margin-left: 20px;
}

.ml-10px {
  margin-left: 10px;
}

.ml-25px {
  margin-left: 25px;
}

.ml-50px {
  margin-left: 50px;
}

.mr-100px {
  margin-right: 100px;
}

.mr-10px {
  margin-right: 10px;
}

.mb-50px {
  margin-bottom: 50px;
}

.mr-50px {
  margin-right: 50px;
}
/*
.sub-nav {
  width: 1280px !important;
}*/

.nav-tabs .nav-item {
  margin-bottom: -1px;
  background: #a9a9a9;
  border: 5px solid #f5f5f5;
  min-width: 250px;
  text-align: center;
}

.sub-nav .nav-item {
  background: #a9a9a9 !important;
  border: 2.5px solid #f5f5f5;
}

  .sub-nav .nav-item a {
    color: #000 !important;
  }

.nav-tabs li:hover {
  background: #000;
}

.nav-tabs li a {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.nav-tabs li:hover {
  padding: 0px !important;
}

.nav-tabs li:hover {
  background-color: #000;
}

.nav-tabs li {
  padding: 0px !important;
}

.sub-nav .nav-tabs li:hover {
  background: #000 !important;
  color: #fff;
}

.nav-tabs {
  border-bottom: unset;
}

  .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    border-color: unset !important;
  }

  .nav-tabs .nav-link {
    border: unset;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
  }

#navbarSubContent .nav-item {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.btn-md {
  width: 138.8px !important;
}

/*.help-fixed-c:hover i {
  background: #000 !important;
  color: #fff;
}*/

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  border: 3px solid #dedcdcbf;
  background-clip: unset;
  border-radius: unset;
  /* border-radius: .3rem; */
  outline: 0;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: unset;
  /* border-bottom: 1px solid #dee2e6; */
  /* border-top-left-radius: calc(.3rem - 1px); */
  /* border-top-right-radius: calc(.3rem - 1px); */
}

.close {
  opacity: 1 !important;
}

.mid-banner-list li {
  text-align: initial !important;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: .75rem;
  border-top: unset;
  /* border-top: 1px solid #dee2e6; */
  border-bottom-right-radius: calc(.3rem - 1px);
  border-bottom-left-radius: calc(.3rem - 1px);
}

.multiselect-dropdown .dropdown-btn .selected-item {
  border: 1px solid #88be42 !important;
  margin-right: 4px;
  background: #88be42 !important;
  padding: 0 5px;
  color: #fff;
  border-radius: 0px !important;
  float: left;
}

.multiselect-dropdown .dropdown-btn {
  border: 2px solid #d3d3d3 !important;
  background: #fff !important;
  display: block;
  width: 100%;
  padding: 10px 10px !important;
  font-size: 16px !important;
  font-family: Interstate;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dedcdcbf;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  /*box-shadow: 1px 1px 1px #d8d8d8;*/
  outline: 0;
  border-radius: unset !important;
  min-height: 50px;
}

select.form-control {
  appearance: none !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='darkgray' height='40' viewBox='0 0 24 24' width='40' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>") !important;
  background-repeat: no-repeat !important;
  background-position-x: 100% !important;
  background-position-y: 5px !important;
}

.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret {
  height: 50px !important;
}

  .multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret:before {
    color: darkgray !important;
    border-color: darkgray transparent !important;
  }

.back-arrow:hover {
  color: black !important;
  cursor: pointer;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff !important;
  background-color: #000;
  border-radius: unset;
  width: 100%;
}

.form-control:focus {
  box-shadow: unset;
}

select.form-control:hover {
  cursor: pointer;
}

.accordion-header {
  font-weight: 500;
}

#accordion .card {
  border: 2px solid #87be40;
  border-radius: 0px !important;
}

#accordion .card-header {
  border-radius: 0px !important;
  border: 2px solid #87be40;
  height: 62.5px;
  width: 325px;
}

.card-header {
  padding: 0.75 rem 1.25 rem;
  margin-bottom: 0;
  background-color: rgba(0,0,0,.03);
  border-bottom: 1 px solid rgba(0,0,0,.125);
  background: #87be40;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
}

  .card-header:first-child {
    border-radius: unset !important;
  }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  /* background-clip: border-box; */
  border: 1px solid rgb(0 0 0 / 45%) !important;
  border-radius: unset;
}

.toast-container .ngx-toastr:hover {
  box-shadow: unset !important;
  opacity: 1;
  cursor: pointer;
}

.toast-warning {
  background-color: #F4A460 !important;
}

.toast-success {
  background-color: #3cb371 !important;
}

.welcome-modal {
  display: flex !important;
  align-items: center !important;
  backdrop-filter: blur(1px);
}

  .welcome-modal .modal-content {
    width: 500px;
    border: 5px solid #a9a9a9;
    border-radius: 0 !important;
    height: 250px;
    text-align: center;
    font-size: 26px;
    padding: 75px;
  }

.confirm-modal {
  display: flex !important;
  align-items: center !important;
}

  .confirm-modal .modal-content {
    width: 325px;
    border: 2px solid #88be42;
    border-radius: 0 !important;
  }

  .confirm-modal .modal-header {
    border-radius: 0 !important;
    border: 2px solid #88be42;
    min-height: 62.5px;
    width: 325px;
    background: #88be42;
  }

    .confirm-modal .modal-header h4 {
      font-weight: 500;
      font-size: 16px;
      vertical-align: middle;
      align-items: center;
      height: 100%;
      color: #fff;
      width: 90%;
    }

  .confirm-modal .modal-dialog {
    max-width: 325px;
    margin: 1.75rem auto;
  }

  .confirm-modal .modal-body {
    padding: 20px !important;
  }

.modal-small {
  display: flex !important;
  align-items: center !important;
}

  .modal-small .modal-body {
    padding: 20px !important;
  }

  .modal-small .modal-content {
    width: 325px;
    border: 2px solid #88be42;
    border-radius: 0 !important;
  }

  .modal-small .modal-header {
    border-radius: 0 !important;
    border: 2px solid #88be42;
    height: auto;
    /*width: 325px;*/
    background: #88be42;
  }

    .modal-small .modal-header h4 {
      font-weight: 500;
      font-size: 16px;
      vertical-align: middle;
      align-items: center;
      height: 100%;
      color: #fff;
    }

  .modal-small .modal-dialog {
    max-width: 325px;
    margin: 1.75rem auto;
  }

.modal-xl .modal-header {
  border-radius: 0 !important;
  border: 2px solid #88be42;
  height: 62.5px;
  background: #88be42;
}

.modal-xl .modal-content {
  border: 2px solid #88be42;
  border-radius: 0 !important;
}

.modal-xl h4 {
  font-weight: 500;
  font-size: 16px;
  vertical-align: middle;
  align-items: center;
  text-align: center;
  height: 100%;
  color: #fff;
  width: 100%;
}

.close {
  text-shadow: unset !important;
  opacity: 1;
  color: #fff !important;
}

  .close:hover {
    color: #fff;
  }

.secondary-btn:focus {
  border-radius: unset;
}

button.btn.btn-outline-secondary.calendar.fa.fa-calendar {
  letter-spacing: 1px;
  height: 50px;
  padding: .375rem .75rem;
  color: #000;
  background-color: #a9a9a9;
  font-size: 16px;
  min-width: 94.8px;
  border: 1px solid #000;
  width: auto;
}

  button.btn.btn-outline-secondary.calendar.fa.fa-calendar:hover {
    background: #000;
    color: #fff;
  }

  button.btn.btn-outline-secondary.calendar.fa.fa-calendar:focus {
    outline: unset !important;
  }

  button.btn.btn-outline-secondary.calendar.fa.fa-calendar:hover {
    outline: unset !important;
  }

.btn-outline-primary:hover {
  color: #fff;
  background-color: #88be42;
  border-color: #88be42;
}

.btn-outline-primary {
  color: #88be42;
  border-color: #88be42;
}

  .btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #88be42;
    border-color: #88be42;
  }

span.ngb-dp-navigation-chevron {
  color: #88be42;
}

.btn-link {
  color: #fff; /*#88be42 !important;*/
}

  .btn-link:hover {
    color: #fff;
    text-decoration: underline;
  }

.ngb-dp-weekday {
  color: #88be42 !important;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #88be42;
  border-color: #88be42;
  border-radius: unset !important;
  text-decoration: none !important;
}

.list-group-item {
  border-radius: unset !important;
}

.modal-xl .modal-body {
  padding: 20px;
}

.mail-task {
  margin-bottom: 30px;
}

textarea {
  height: 150px !important;
}

.btn-group-vertical button {
  margin-bottom: 5px;
}

.service-box-primary {
  background: #dcdcdc;
  border: 5px solid #a9a9a9;
  height: 220px;
  padding: 15px;
}

  .service-box-primary h3 {
    font-size: 20px;
    text-align: center;
    margin-top: 10px;
  }

  .service-box-primary a {
    text-decoration: none;
  }

.primary-content i {
  font-size: 30px;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 10px;
}

.primary-content p {
  border-top: 3px solid #a9a9a9;
  font-size: 14px;
}

.globe-notify i {
  font-size: 20px;
  font-weight: 400;
  transform: rotate( 270deg );
  vertical-align: middle;
  padding-right: 16px;
}

.globe-notify .badge {
  transform: rotate( 270deg );
  vertical-align: top;
  padding: 5px;
  margin: -21px 0px;
}

.globe-notify {
  position: fixed;
  right: 0%;
  top: 245px;
  cursor: pointer;
  z-index: 1;
  background: #87be40;
  color: #fff;
  font-size: 14px;
  height: 40px;
  font-weight: 700;
  overflow: hidden;
  transform: rotate( 90deg );
  transform-origin: right top;
  width: 70px;
  padding-left: 18px;
}

  .globe-notify.my-team {
    top: 400px;
  }

.my-tasks-content {
  background: #87be40;
  width: 100%;
  padding: 0px 50px 50px 50px;
  text-align: center;
  display: block;
  border-radius: 0px;
  width: 75vw;
  height: 100vh;
  z-index: 9999999;
  position: fixed;
  bottom: 0px;
  right: 0px;
  font-weight: normal;
}

  .my-tasks-content .panel-header {
    border-radius: 0 !important;
    height: 60px;
    display: flex;
    align-items: baseline;
  }

    .my-tasks-content .panel-header h4 {
      font-weight: 500;
      font-size: 20px;
      vertical-align: middle;
      align-items: center;
      height: 100%;
      padding: 10px 0;
      color: #fff;
      width: 90%;
    }

    .my-tasks-content .panel-header button.close {
      width: 10%;
      text-align: end;
      padding-right: 10px;
    }

.notification-content .panel-header {
  border-radius: 0 !important;
  height: 62.5px;
  display: flex;
  align-items: baseline;
}

  .notification-content .panel-header h4 {
    font-weight: 500;
    font-size: 20px;
    vertical-align: middle;
    align-items: center;
    height: 100%;
    padding: 10px 0;
    color: #fff;
    width: 90%;
  }

  .notification-content .panel-header button.close {
    width: 10%;
    text-align: end;
    padding-right: 10px;
  }

.latest-updates .form-control img {
  height: 30px;
  padding: 0px 30px;
}

.latest-updates .form-control {
  background: #fff;
  height: 50px;
  font-size: 18px;
  padding-top: 10px;
  border: 2px solid #000;
}

.latest-updates label {
  background: #000;
  margin: 0px;
  color: #fff;
  padding: 0px 10px;
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  color: #fff !important;
}

.content-area li.nav-item {
  border: 0px solid transparent !important;
  border-radius: unset;
}

.accordion > .card > .card-header .btn.btn-link {
  text-decoration: unset;
  outline: none;
}

.no-focusborder:focus {
  outline-style: none;
  box-shadow: none;
  border-color: transparent;
  background-color: black;
  color: white;
}

.accordion > .card > .card-header .btn.btn-link:focus {
  outline-style: none;
  box-shadow: none;
  border-color: transparent;
}

.bg-review {
  background: #87be40 !important;
  color: #fff !important;
}

.riskred {
  background: #FF0000 !important;
  color: #fff !important;
  font-size: 14px !important;
}

.riskamber {
  background: #FFA500 !important;
  color: #fff !important;
  font-size: 14px !important;
}

.riskorange {
  background: orange !important;
  color: #fff !important;
  font-size: 16px !important;
}

.riskbrown {
  background: #8B0000 !important;
  color: #fff !important;
  font-size: 14px !important;
}

.riskgreen {
  background: #3FBF3F !important;
  color: #fff !important;
  font-size: 14px !important;
}


.riskyellow {
  background: yellow !important;
  color: #fff !important;
  font-size: 16px !important;
}

.riskblue {
  background: blue !important;
  color: #fff !important;
  font-size: 16px !important;
}

.riskdefault {
  background: #d3d3d3 !important;
}

.disabled {
  cursor: not-allowed;
}





.tooltip,
.container__sources:before,
.container__build:before,
.container__deploy:before {
  position: absolute;
  right: 0;
  bottom: 100%;
  color: #fff;
  background: #ffb238;
  text-transform: uppercase;
  font-size: 0.9rem;
  padding: 0.25rem 0.75rem;
  border-radius: 2.5px;
}

.container__sources div,
.container__build div {
  line-height: 2;
  background: #fff;
  padding: 1.2rem 1rem;
  border-radius: 4px;
  box-shadow: 0 2px 10px #e6e6e6;
}

.container svg {
  height: 5rem;
}

  .container svg line {
    stroke: #5f39dd;
    stroke-width: 3px;
    stroke-linecap: round;
    stroke-dasharray: 2px 20px;
    animation: animateline 5s linear both infinite;
  }

svg rect, .container__sources {
  display: flex;
  border-radius: 8px;
  position: relative;
}

.container .container__sources {
  justify-content: center;
}

svg rect, .container__sources div {
  text-align: left;
  margin: 0 1rem;
}

.container__build {
  padding: 10vh 10vw;
  border-radius: 8px;
  background: #f9f9f9;
  position: relative;
}

  .container__build div {
    margin: 2rem 0;
  }

    .container__build div svg {
      width: 4rem;
      height: auto;
      fill: #5f39dd;
    }

.container__deploy {
  background: #f9f9f9;
  padding: 1.5rem;
  border-radius: 8px;
  position: relative;
}

@media (max-width: 700px) {
  .container__sources {
    flex-direction: column;
  }

    .container__sources div {
      margin: 1rem 0;
    }
}

@-moz-keyframes animateline {
  from {
    stroke-dashoffset: 0;
  }

  to {
    stroke-dashoffset: -5rem;
  }
}

@-webkit-keyframes animateline {
  from {
    stroke-dashoffset: 0;
  }

  to {
    stroke-dashoffset: -5rem;
  }
}

@-o-keyframes animateline {
  from {
    stroke-dashoffset: 0;
  }

  to {
    stroke-dashoffset: -5rem;
  }
}

@keyframes animateline {
  from {
    stroke-dashoffset: 0;
  }

  to {
    stroke-dashoffset: -5rem;
  }
}

